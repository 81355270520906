@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');

body {
  padding: 0;
  font-family: "Lato", sans-serif;

  color: #444;
  background: url("http://res.cloudinary.com/dqjh46sk5/image/upload/v1678209603/dtx9j0ztinlrniaspys4.jpg") no-repeat center;
  background-size: cover;
  margin: 0;
  scrollbar-width: none;
  

}
.app{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

}
body::-webkit-scrollbar {
  display: none;
};
.name{
  font-size: 3em;
  font-weight: 700;
}
.lastName{
  font-weight: 300;
}


footer {
width: 85%;
max-width: 85em;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  position: sticky;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
align-items: center;
  background-color: #b991ff;
  bottom: 0;
  height: 5.5vh;
  min-height: 45px;
 
}
@media only screen and (max-width: 768px) {
  footer {
  width: 100%;
  }
}
i {
  color: whitesmoke;;
}

.active::before {
  width: 100%;
  color: rgb(96, 41, 151);
}